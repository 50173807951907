@import url('https://fonts.googleapis.com/css?family=Carter+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ranchers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cherry+Cream+Soda&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@700&display=swap');

.Giant-container {
  display:flex;
  height: 100vh;
  width: 100vw;
  background-color: #9db8bb;;
  align-items:center;
 justify-content:flex-start;
 flex-direction:column;
}
.Giant-container p{
  padding: 0px 20% 0px 20%
}

/********** Nav Bar **********/

.navbar-container {
  display:flex;
  justify-content:center;
  align-items: center;
  background-color: rgb(46 119 128);
  padding:0px;
  top:0px;
  margin:0px;
  width:100vw;
  height: 100px;
}

.title-banner {
  color:white;
  text-shadow:1px 2px 1px black;
  justify-content:center;
  align-items: center;
}

.logo-banner {
  border-radius: 50%;
  width:60px;
  height:60px;
  background-color:rgb(255, 255, 255);
  box-shadow: 4px 2px 5px rgb(77, 77, 77);
}

li {
  float:left;

}
.operation-button-container {
  display:flex;
  list-style-type:none;
  border:none;
  padding:0px;
}
.operation-button {
  padding: 0px 10px 0px 10px;
  margin: 0px 10px;
  background-color: rgb(90, 72, 100);
  background-image: linear-gradient(to bottom right, rgb(203 139 204), #4a2644);
  font-size: 55px;
  line-height: 3px;
  width: 65px;
  height: 60px;
  border: none;
  color: white;
  border-radius: 50%;
  font-family: "Cherry Cream Soda";
  box-shadow: 1px 2px 4px 1px #6b686b;
}
.operation-button:hover {
  background-image: linear-gradient(to bottom right, rgb(136, 82, 150), rgb(69, 53, 78));
  cursor:pointer;
  box-shadow: 1px 2px 4px 1px #6b686b;

}
.operation-button:focus {
  outline:none;
  background-image: linear-gradient(to bottom right, rgb(136, 82, 150), rgb(69, 53, 78));
}

/************* Problem Card **************/
.problem-card-container{
  display:flex;
  justify-content: center;
  background-color: #dcdcdc;
  height: 100vh;
  width: 60vw
}
.problem-card {
  border: 10px solid #778f9e;
  color:rgb(47 89 109);
  font-weight:bold;
  font-size: 70px;
  font-style: arial;
  text-shadow:1px 2px 1px black;  
  padding: 5px 0px 0px 0px;
  display:flex;
  border-radius: 30px;
  margin-top:100px;
  height: 400px;
  width: 600px;
  text-align:center;
  justify-content: flex-start;
  flex-direction:column;

  background: white;
  box-shadow: 20px 20px 20px #888585;
}
.card-title {
  font-family: "Cherry Cream Soda";
  text-align:center;
  font-size: 35px;
  background-image: linear-gradient(to bottom right, rgb(56, 26, 56), rgb(117 67 130));
  color: white;
  background-clip:content-box;
  padding:0px 0px 5px 0px;
  border-top-left-radius: 10px;    
  border-top-right-radius: 10px;

}
.card-title p {
  padding: 0px, 0px, 0px, 0px;
  border-top-left-radius: 10px;    
  border-top-right-radius: 10px;
  margin: 0;
}
.card-top {
  font-family:"Yanone Kaffeesatz";
  position:relative;
  top:0;
  margin: 0;
  padding: 0;
  color: white;
  justify-content:top;
  font-size: 35px;
  background-image: linear-gradient(to bottom right, #734173, rgb(198, 141, 212));

}
.card-top p {
  margin: 0;
  padding: 0px 0px 0px 0px;

}
.question {
  width: 270px;
  padding: 0px 10px 0px 40px;
}
.question-answer {
  display:flex;
  font-family: "Ranchers";
  flex-grow:2;
  justify-content:flex-start;
  border-radius: 20px;
  align-items:center;
  text-shadow: 2px 2px 2px #bdb1b1
  
}

input {
  font-size: 70px;
  font-weight: bold;
  border-radius: 40px;
  text-align:center;
  border-color: grey;
  border-style:solid;
  font-family: "Ranchers";
  color: rgb(117, 76, 133);
  text-shadow: 1px 2px 1px black;
}
input:focus{
  border-radius: 40px;
  border-color: purple;
  border-style: solid;
  outline:none;
  border-width: 5px 5px 5px 5px
}
input[type="text"] {
  width: 200px;
  height: 100px;
}


.answerCheck {
  font-size: 35px;
  color:green;
}
.star {
  width: 30px;
}
.redX{
  color:red;
  font-size:2rem;
  font-weight:bold;
  font-family: "Ranchers";
  margin:0;
}

h1 {
  font-family: "Cherry Cream Soda";
}


/************** Question Table Data ***************/
.tableData{
  display:flex;
  flex-direction: column;
  align-items:center;
}
.tableData-container{
  width: 70vw;
  height: calc(100vh - 100px);
  background-color: #dcdcdc;
}
table {
  border: 10px solid #794080;
  font-size:30px;
  font-family:"Ranchers";
}
tbody{
  background-color: white;
}
th {
  background-color:#1e5d65;
  color: white;
  font-size: 30px;
  font-family:"Cherry Cream Soda"
}
.td-answerCheck{
  display:flex;
}
tr:nth-child(even) {background-color: #c6e5e8;}

.table-accuracy {
  font-family:"Ranchers";
}

.try-again {
  font-family:"Cherry Cream Soda";
  border: none;
  width: 120px;
  height: 50px;
  margin: 5px;
  background-color: rgb(150 121 150);
  border-radius: 10px;
  background-image: linear-gradient(68deg, #481048, transparent);
  color: white;

}

.try-again:hover {
  border-bottom:1px solid white;
  background-color:rgb(129, 55, 129);
  cursor:pointer;
}




@media(max-width:800px){
  .Giant-container p{
    padding: 0px 10% 0px 10%
  }
  .problem-card{
    width:350px;
    font-size: 50px;
  }
  .inputAnswer{
    margin-right:10px;
  }
  .question{
    padding-left:10px;
    width:150px;
  }
  .card-title{
    height:50px;
  }
  input[type='text']{
    width: 100px;
    height: 80px;
  }
  input{
    font-size:50px;
  }
  .operation-button{
    width: 55px;
    height: 54px;
    font-size: 45px;
  }
  .title-banner{
    font-size:1.7rem;
  }
}




@media(max-width:700px){
  .title-banner{
    width:200px;
    
  }
  .operation-button-container{
    flex-wrap:wrap;
    width: 200px;
  }
  .navbar-container{
    height:120px;
  }
  .card-top{
    font-size: 30px;
  }
  .problem-card{
    font-size:43px;
  }
  .problem-card-container{
    width:90vw;
  }
  input[type='text']{
    width: 100px;
    height: 70px;
  }
}